<template>
  <v-container fluid>
    <v-main class="mx-auto">
      <add-material-form
        v-if="newMaterial"
        ref="addForm"
        :loading="loading"
        form-title="Agregar material"
        @save="saveMaterial"
      />
    </v-main>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AddMaterialForm from '@/components/material/AddMaterialForm'
import materialService from '@/services/materialService'

export default {
  name: 'Add',
  components: {
    AddMaterialForm
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('materials', ['newMaterial'])
  },
  async mounted () {
    if (!this.newMaterial) {
      this.$store.commit('materials/SET_MATERIAL_FORM_IS_MODIFIED', false)
      const lastMaterial = await materialService.getLastMaterialByOrder()
      await this.setNewMaterial({
        name: '',
        level: null,
        order: lastMaterial ? Number(lastMaterial.order) + 1 : 0,
        type: '',
        blocks: []
      })
    }
  },
  async beforeDestroy () {
    const to = this.$router.history.current
    if (to.name !== 'MaterialPreview') {
      await this.setNewMaterial(null)
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (to.name === 'MaterialPreview' || !this.$store.state.materials.materialFormIsModified) {
      next()
      return
    }
    const res = confirm('¿Estás seguro? Si sales perderás los cambios no guardados.')
    if (res) {
      next()
    }
  },
  methods: {
    ...mapActions('materials', ['addMaterial', 'setNewMaterial']),
    async saveMaterial (material) {
      if (await this.$refs.addForm.validateForm()) {
        try {
          this.loading = true
          await this.addMaterial(material)
          this.loading = false
          await this.$root.$confirm({
            message: `Se ha agregado '${material.name}' a la lista de materiales`,
            showDismissButton: false
          })
          this.$router.push({ name: 'Materials' })
        } catch (e) {
          await this.$root.$confirm({
            message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
            showDismissButton: false,
            type: 'alert'
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
